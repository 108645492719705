<script lang="ts" setup>
export interface VSelectionOption {
    value: string
    label: string
}

export interface VSelectProps {
    id?: string
    label?: string
    dialogTitle?: string
    name?: string
    modelValue?: string
    error?: string
    loading?: boolean
    required?: boolean
    disabled?: boolean
    placeholder?: string
    closeOnSelect?: boolean
    icon?: string
    options?: VSelectionOption[] | (() => Promise<VSelectionOption[]>)
}

const props = withDefaults(defineProps<VSelectProps>(), {
    closeOnSelect: true,
})
const emits = defineEmits(['update:modelValue'])

const selectElement = ref<HTMLSelectElement | undefined>()
const dialogOpen = ref(false)

const value = computed<string | undefined>({
    get() {
        return props.modelValue
    },
    set(value) {
        emits('update:modelValue', value)
    },
})

watch(value, () => {
    if (props.closeOnSelect) {
        dialogOpen.value = false
    }
})

const onMouseDown = (event: MouseEvent) => {
    if (window && window.matchMedia('(max-width: 767px)').matches) {
        return
    }

    event.preventDefault()

    dialogOpen.value = true
}

function onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
        case 'Enter':
        case ' ':
        case 'ArrowUp':
        case 'ArrowDown':
            event.preventDefault()
            dialogOpen.value = true
            break
    }
}

watch(dialogOpen, (value) => {
    if (!value && selectElement.value) {
        // Keep focus on select element when dialog is closed
        selectElement.value.focus()
    }
})

const $style = useCssModule()

const internalOptions = ref<VSelectionOption[]>()
const fetchError = ref<string>()
const pending = ref(false)

if (Array.isArray(props.options)) {
    internalOptions.value = props.options
} else if (typeof props.options === 'function') {
    try {
        pending.value = true
        internalOptions.value = await props.options()
        pending.value = false
    } catch (error) {
        pending.value = false
        fetchError.value = (error as Error).message || 'An error occurred while fetching the options'
    }
}
</script>

<template>
    <VInput
        :id="id"
        :required="required"
        :class="$style.root"
        v-bind="$attrs"
        :error="error || fetchError"
        :label="label"
        :disabled="disabled"
    >
        <div
            :class="[
                $style['select__wrapper'],
                disabled && $style['select__wrapper--disabled'],
                !value && $style['select__wrapper--placeholder'],
            ]"
        >
            <SvgIcon v-if="icon" :class="$style.icon" :name="icon" viewBox="0 0 24 24" width="24" height="24" />
            <select
                :id="id"
                ref="selectElement"
                v-model="value"
                :class="[$style.select, value && $style['select--filled']]"
                :name="name"
                :required="required"
                :disabled="disabled"
                @keydown="onKeyDown"
                @mousedown="onMouseDown"
            >
                <option v-if="placeholder" :value="undefined" disabled selected>{{ placeholder }}</option>
                <option v-for="option in internalOptions" :key="option.value" :value="option.value">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <ClientOnly>
            <Teleport to="body">
                <VDialog v-if="dialogOpen" :label="dialogTitle || label" no-action @close="dialogOpen = false">
                    <template #before-close>
                        <slot name="before-dialog-close" />
                    </template>
                    <ul :class="$style.list">
                        <li
                            v-for="option in internalOptions"
                            :key="option.value"
                            :class="[$style.item, option.value === value && [$style['item--selected']]]"
                        >
                            <button :class="$style.item__button" @click.prevent="value = option.value">
                                {{ option.label }}
                            </button>
                        </li>
                    </ul>
                </VDialog>
            </Teleport>
        </ClientOnly>
    </VInput>
</template>

<style lang="scss" module>
.root {
    --v-input-root-width: 100%;
}

.select__wrapper {
    position: relative;
    width: 100%;

    &--disabled {
        color: var(--color-primary-disabled);
        pointer-events: none;
    }
}

.icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 24px;
    height: 24px;
    margin-left: var(--spacing-2-xs);
    color: var(--color-content-secondary);
    pointer-events: none;
    transform: translateY(-50%);

    .select__wrapper--disabled & {
        color: inherit;
    }
}

.select {
    --input-padding: 0 calc(var(--spacing-2-xs) + 28px);

    &--filled {
        font-weight: 500;
    }

    &:not(:disabled) {
        cursor: pointer;
    }
}

.list {
    display: flex;
    max-height: 50vh;
    flex-direction: column;
    padding: var(--spacing-2-xs) var(--spacing-sm);
    margin-bottom: 24px;
    gap: var(--spacing-xs);
    list-style: none;
    overflow-y: auto;
}

.item__button {
    @include text-button-m;

    display: flex;
    width: 100%;
    height: rem(40);
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    border-radius: var(--radius-sm);
    background-color: var(--color-surfaces-secondary);
    color: var(--color-primary);
    padding-inline: rem(12) rem(8);
    transition: border-color 0.2s;
    user-select: none;

    .item--selected & {
        color: var(--color-surfaces-secondary);
        background-color: var(--color-primary);
    }

    @media (hover: hover) {
        &:hover {
            border-color: color(black);
        }
    }
}
</style>
